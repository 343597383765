import { Search } from '@element-plus/icons-vue';
import BaseOption from '../../../common/base-option-dialog.vue';
export default {
  components: {
    Search,
    BaseOption
  },
  data() {
    return {
      table: {},
      pagination: {
        current_page: 1,
        page_size: 10
      },
      total: 0,
      form: {},
      keyword: '',
      labelId: 0,
      labelType: '',
      labelVisible: false,
      formRules: {
        name: [{
          required: true,
          message: '请输入'
        }, {
          pattern: /^[\u4E00-\u9FA5A-Za-z0-9_-]+$/,
          message: '只允许中英文，数字，下划线，中划线'
        }, {
          min: 1,
          max: 10,
          message: '最多只允许输入10位'
        }]
      }
    };
  },
  watch: {
    keyword(val) {
      if (val === '') {
        this.clearAll();
      }
    }
  },
  mounted() {
    this.getLabel();
  },
  methods: {
    async getLabel() {
      try {
        await this.$store.dispatch('system/base/getLabel', {
          params: {
            current_page: this.pagination.current_page,
            page_size: this.pagination.page_size,
            name: this.keyword
          }
        });
        let label = this.$store.state.system.base;
        this.table = {
          tableData: label.label,
          tableFields: [{
            label: 'ID',
            prop: 'id',
            width: '90px',
            show: true
          }, {
            label: '标签名称',
            prop: 'name',
            width: '1030px',
            show: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            width: '400px',
            show: true
          }]
        };
        this.total = label.labelLength;
      } catch (err) {
        return;
      }
    },
    queryList() {
      this.pagination.current_page = 1;
      this.getLabel();
    },
    clearAll() {
      this.pagination = {
        current_page: 1,
        page_size: 10
      };
      this.getLabel();
    },
    changePagination(val) {
      this.pagination = val;
      this.getLabel();
    },
    showLabelDialog(type, id, name) {
      this.labelType = type;
      if (type === 'update') {
        this.labelId = id;
        this.form.name = name;
      } else {
        this.form.name = '';
      }
      this.labelVisible = true;
    },
    closeLabel() {
      this.labelVisible = false;
      this.$refs.form.resetFields();
    },
    async createLabel() {
      try {
        await this.$store.dispatch('system/base/createLabel', {
          name: this.form.name
        });
        this.labelVisible = false;
        this.$refs.form.resetFields();
        this.pagination.current_page = 1;
        localStorage.removeItem('label');
        this.getLabel();
      } catch (err) {
        return;
      }
    },
    async updateLabel() {
      try {
        await this.$store.dispatch('system/base/updateLabel', {
          id: this.labelId,
          name: this.form.name
        });
        this.labelVisible = false;
        this.$refs.form.resetFields();
        localStorage.removeItem('label');
        this.getLabel();
      } catch (err) {
        return;
      }
    },
    async submitLabel() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.labelType === 'create') {
            this.createLabel();
          } else {
            this.updateLabel();
          }
        }
      });
    }
  }
};